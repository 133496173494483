<div class="container area" [attr.dir]="isArabic ? 'rtl' : 'ltr'">
  <mat-card style="z-index: 11111 !important;">
    <header>
      <img class="logoImg" src="assets/img/logo.jpg" />
      <div style="margin-top: 20px;">
      </div>
    </header>
    <mat-card-content>
      <form class="f-form" [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="step == 1">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ 'selectState' | translate }}</mat-label>
          <mat-select placeholder="{{ 'statePlaceholder' | translate }}" formControlName="state">
            <mat-option *ngFor="let item of States" [value]="item.stateName">
              {{ item.stateName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
            !form.controls['state'].valid && form.controls['state'].touched
          ">
            {{ 'stateRequired' | translate }}
          </mat-error>
        </mat-form-field>
        <div class="platecode">
          <mat-form-field class="full-width" appearance="outline">
            <input matInput maxlength="7" minlength="1" placeholder="{{ 'plateTextPlaceholder' | translate }}"
              formControlName="plate" />
          </mat-form-field>
        </div>
        <mat-error class="full-width" style="margin: 0px 0px 20px 0px;" *ngIf="
        !form.controls['plate'].valid && form.controls['plate'].touched
      ">
          {{ 'plateTextRequired' | translate }}
        </mat-error>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{ 'selectSite' | translate }}</mat-label>
          <mat-select placeholder="{{ 'sitePlaceholder' | translate }}" formControlName="site">
            <mat-option *ngFor="let item of Sites" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
              !form.controls['site'].valid && form.controls['site'].touched
            ">
            {{ 'siteRequired' | translate }}
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!(form.valid) || !(form.valid)">
          {{ 'sendButton' | translate }}
        </button>
      </form>
      <div *ngIf="step===2" class="step_two">
        <p>{{'CAR_PAY_WORDS'| translate}} <span>{{form.get('plate')?.value}} </span>
          {{'CAR_AMOUNT'| translate}}<span dir="ltr" style="margin: 0 0.5rem;">{{amount}}</span>دينار</p>
        <button mat-raised-button color="primary" (click)="onPay()">{{'Pay'| translate}}</button>
      </div>
    </mat-card-content>`
  </mat-card>

  <ul class="circles">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
</div>