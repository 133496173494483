import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, Subject, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class ApiHttpService implements HttpInterceptor {
  static Xtenent = 'org_LcsIqfuqLSCPC7A5';
  static siteName = 'المركز العلمي';
  static onExit = false;
  siteObservable = new Subject<any>();
  constructor(
    private router: ActivatedRoute,
    private http: HttpClient,
    private snack: MatSnackBar,
  ) {
    this.router.queryParams.subscribe((params: any) => {
      console.log(params);
      if (params.tenantId && params.siteName) {
        ApiHttpService.Xtenent = params.tenantId;
        ApiHttpService.siteName = params.siteName;
        this.publishObservale({ site: ApiHttpService.siteName })
      }
      if (params.onExit) {
        ApiHttpService.onExit = params.onExit
      }
    })
  }
  publishObservale(data: any) {
    this.siteObservable.next(data)
  }
  getObservable(): Observable<any> {
    return this.siteObservable
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(req)
    return next.handle(req).pipe(
      catchError((error) => {
        console.log(error)
        if (error.status === 400 && error.url.includes('public-activities')) {
          this.showSuccessCssStyles(error.error?.error ? error.error?.error : 'you must be at the exit barrier to be able to use the on-exit QR code', 'ok', { duration: 40000 })
        }

        throw new Error('Method not implemented.');
      }))
  }
  showSnackbarCssStyles(content: string, action: string | undefined, duration: any) {
    let sb = this.snack.open(content, action, {
      duration,
      panelClass: ['error'],
    });
    sb.onAction().subscribe(() => {
      sb.dismiss();
    });
  }
  showSuccessCssStyles(content: string, action: string | undefined, duration: any) {
    let sb = this.snack.open(content, action, {
      duration,
      panelClass: ['success'],
      verticalPosition: 'top'

    });
    sb.onAction().subscribe(() => {
      sb.dismiss();
    });
  }
  get(url: String, data?: any): Observable<any> {
    return this.http.get(`${url}`, data)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      )
  }
  public post(url: String, data: any, options?: any): Observable<any> {
    return this.http.post(`${url}`, data, options)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      )
  }
  getStates() {
    console.log("states")
    let headers = new HttpHeaders()
      .set("X-TENANT-ID", ApiHttpService.Xtenent);
    let ApiUrl = `https://dev.api.parking.ldcs.ae/api/v1/state-code/list`;
    return this.get(ApiUrl, { headers: headers, observe: "response" });
  }

  getSites() {
    let ApiUrl = `https://staging.users.parking.ldcs.ae/car/site`;
    return this.get(ApiUrl);
  }
  postRequest(plate: string, state: string) {
    let headers = new HttpHeaders().set("X-TENANT-ID", ApiHttpService.Xtenent);
    let ApiUrl = `https://api.parking.ldcs.ae/api/v1/public-activities/info?plate=${plate}&state=${state}&site=${ApiHttpService.siteName}&onExit=${ApiHttpService.onExit}`;
    return this.get(ApiUrl, { headers: headers, observe: "response" });
  }
  pay(data: { amount: any;}) {
    let ApiUrl = `https://dev.api.parking.ldcs.ae/knet/payment-link`;
    return this.post(ApiUrl, data);
  }
  getAmount(data: { licensePlate: any;}) {
    let ApiUrl = `https://dev.api.parking.ldcs.ae/knet/get-car-fees`;
    return this.post(ApiUrl, data);
  }
}
