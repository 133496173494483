import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { ApiHttpService } from '../core/service/api-http.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-kiosk-page',
  templateUrl: './kiosk-page.component.html',
  styleUrls: ['./kiosk-page.component.scss'],
})
export class KioskPageComponent implements OnInit {
  BillResponse: any = {};
  id: string = '';
  step: number = 1;
  amount: number = 0;
  Sites: any[] = [];
  States: State[] = [];
  url: string = 'https://staging.engine.parking.ldcs.ae/';
  showPayButton: boolean = false;
  disabled: boolean = true;
  dataSend: ResponceData = {
    arrival: '',
    activityId: '',
    userId: null,
    plate: '',
    state: '',
    site: ''
  };
  public form: FormGroup = new FormGroup({
    state: new FormControl('', { validators: Validators.required }),
    site: new FormControl('', { validators: Validators.required }),
    plate: new FormControl('', { validators: Validators.required }),
  });
  isArabic: boolean;

  constructor(
    private api: ApiHttpService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('ar');
    this.isArabic = true;
  }

  ngOnInit(): void {
    this.Sites.push(ApiHttpService.siteName);
    this.form.controls['site'].setValue(ApiHttpService.siteName);
    this.getStates();
    this.api.getObservable().subscribe((data: any) => {
      if (data.site) {
        this.Sites = [];
        this.Sites.push(data.site);
        this.form.controls['site'].setValue(data.site);
      }
    });
  }
  onPay() {
    this.showPayButton = true;
    const data = {
      amount: this.amount, 
    };
    this.api.pay(data).subscribe(response => {
      console.log(response);
      if (response?.url) {
        window.location.href = response?.url;
      } else {
        this.api.showSuccessCssStyles(
          this.translate.instant('paymentSuccessMessage'),
          'ok',
          { duration: 40000 }
        );
      }
    });
  }
  onSubmit() {
    this.step = 2;
    this.api.getAmount({
      licensePlate: this.form.get('plate')?.value,
    }).subscribe(res=>{
      console.log(res);
      this.amount = res?.amount
    })
  }
  getStates() {
    this.api.getStates().subscribe((res) => {
      console.log(res);
      this.States = [{
        stateName: this.translate.instant('kuwaitState'),
        plate_codes: []
      }]
      this.form.controls['state'].setValue(this.translate.instant('kuwaitState'));
    });
  }
}

export interface Bill {
  publicId: string;
  publicBill: {
    publicId: string;
    amount: number;
  };
}

export interface State {
  stateName: string;
  plate_codes: [];
}

export interface ResponceData {
  arrival: string;
  activityId: string;
  userId: string | null;
  plate: string;
  state: string;
  site: string;
}

export interface UrlData {
  tenantId: string;
  arrival: string;
  departureAttempt: string;
  plate: string;
  state: string;
  site: string;
  userId: string;
  activityId: string;
  type: number;
  method: string;
  nested: Nested[];
}

interface Nested {
  areaType: string;
  timeSpent: number;
}
